/**
**
** Footer Section
** Override to center footer legal copy
**
**/
.div-block-39 {
  justify-content: center;
}
/**
**
** Headliner Component
**
**/
h1.headliner {
  font-weight: 800;
  font-size: 15vw;
}
/**
**
** Projects Section
**
**/

/**
  ** Showcase Projects Section
**/
.cs-bg-div.wms-case-study {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url(assets/images/projects/b97ee7963.jpeg);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}
.cs-bg-div.belizeangroceries {
  background-image: linear-gradient(rgba(9, 62, 78, 0.25), rgba(0, 0, 0, 0.65)),
    url(assets/images/projects/b97ee7964.jpeg);
}
.cs-bg-div.moment {
  background-image: url(assets/images/projects/b97ee7966.jpeg);
}
.cs-bg-div.handy {
  background-image: url(assets/images/projects/b97ee7972.jpeg);
}

/**
  ** Mobile Projects Section
**/
.cs-bg-div.nona-case-study {
  background-image: linear-gradient(rgba(43, 42, 27, 0.55), rgba(34, 31, 31, 0.15)),
    url(assets/images/projects/b97ee7967.jpeg);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}
.cs-bg-div.compass-case-study {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(34, 31, 31, 0.15)),
    url(assets/images/projects/b97ee7968.jpeg);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}
.cs-bg-div.dealer {
  background-image: linear-gradient(rgba(24, 24, 23, 0.95), rgba(22, 14, 14, 0.35)),
    url(assets/images/projects/b97ee7970.jpeg);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}

/**
  ** Belize Projects Section
**/
.cs-bg-div.transport {
  background-image: linear-gradient(rgba(24, 24, 23, 0.95), rgba(22, 14, 14, 0.35)),
    url(assets/images/projects/b97ee7971.gif);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}
.cs-bg-div.vehicles {
  background-image: linear-gradient(rgba(24, 24, 23, 0.95), rgba(22, 14, 14, 0.35)),
    url(assets/images/projects/b97ee7971.gif);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}
.cs-bg-div.yard {
  background-image: linear-gradient(rgba(24, 24, 23, 0.95), rgba(22, 14, 14, 0.35)),
    url(assets/images/projects/b97ee7971.gif);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}
.cs-bg-div.commerce {
  background-image: linear-gradient(rgba(24, 24, 23, 0.95), rgba(22, 14, 14, 0.35)),
    url(assets/images/projects/b97ee7971.gif);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}
.cs-bg-div.security {
  background-image: linear-gradient(rgba(24, 24, 23, 0.95), rgba(22, 14, 14, 0.35)),
    url(assets/images/projects/b97ee7971.gif);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}

/**
** Homescreen | Github
**/
.github {
  border-radius: 3px;
  opacity: 0.6;
}